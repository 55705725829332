<template>
    <div class="view summitview-landing-page">
        <section>
            <div class="flex-container">
                <PageHero id="home">
                </PageHero>
            </div>
        </section>
        <div class="event-registration">
            <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
                <form @submit.prevent="handleSubmit(onSubmit)">
                    <div class="desc">
                        <div class='content'>
                            <h1>Win $100 worth of home care from Gravitii</h1>
                            <div class="details">
                                <p>Enter your name and email address below and click or tap "Submit" to be entered into a random draw for $100 of home care services through https://gravitii.care</p>
                                <p><i>Contest closes on November 8th, 2024 @ 11:59 pm.  A winner will be announced on November 15th, 2024.</i></p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div v-show="!showSuccess">
                            <div class="field">
                                <ValidationProvider name="First name" rules="required" v-slot="{ errors }">
                                    <label for="firstname">
                                        First name <span v-if="errors[0]" class="err"> ({{ errors[0] }})</span>
                                    </label>
                                    <kInput id="firstname" v-model="firstname" type="text" placeholder="" />
                                </ValidationProvider>
                            </div>
                            <div class="field">
                                <ValidationProvider name="First name" rules="required" v-slot="{ errors }">
                                    <label for="lastname">
                                        Last name <span v-if="errors[0]" class="err"> ({{ errors[0] }})</span>
                                    </label>
                                    <kInput id="lastname" v-model="lastname" type="text" placeholder="" />
                                </ValidationProvider>
                            </div>
                            <div class="field">
                                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                                    <label for="email">
                                        Email <span v-if="errors[0]" class="err"> ({{ errors[0] }})</span>
                                    </label>
                                    <kInput id="email" v-model="email" type="email" placeholder="you@domain.com" />

                                </ValidationProvider>
                            </div>

                            <div class="buttons">
                                <gButton id="submit" @click.prevent="handleSubmit(onSubmit)" :disabled='sending||invalid'>Submit</gButton>
                            </div>
                        </div>
                        <Transition>
                            <div v-show="showSuccess" class="success">Thank you for entering!</div>
                        </Transition>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import { Input } from '@progress/kendo-vue-inputs';
    import PageHero from '../../components/PageHeroSmall.vue';
    import AppButton from '../../components/AppButton.vue';

    //import SectionBadges from '../../components/SectionBadges.vue';
    //import SectionCoreFeatures from '../../components/SectionCoreFeatures.vue';
    //import SectionGetStarted from '../../components/SectionGetStarted.vue';
    //import SectionTestimonials from '../../components/SectionTestimonials.vue';
    //import FAQ from '../../components/faq/FAQ.vue';

    let meta = {
        type: "website",
        title: "On-demand, quality home care",
        description: "Take your home care experience into your own hands. With Gravitii, you have complete flexibility and control.",
        image: window.location.origin + "/summit-on-aging/feature.jpg"
    };

    let showSuccess = false;
    let invalid = false;
    let sending = false;
    let firstname = "";
    let lastname = "";
    let email = "";

    export default {
        name: "summitLandingPageView",
        components: {
            PageHero,
            gButton: AppButton,
            ValidationObserver,
            ValidationProvider,
            kInput: Input
            //SectionBadges,
            //SectionCoreFeatures,
            //SectionGetStarted,
            //SectionTestimonials,
            //FAQ
        },
        data() {
            return {
                showSuccess,
                invalid,
                sending,
                firstname,
                lastname,
                email,
            };
        },
        metaInfo() {
            return {
                title: meta.title,
                meta: [
                    { name: 'description', content: meta.description },

                    { property: 'og:url', content: window.location.origin },
                    { property: 'og:type', content: meta.type },
                    { property: 'og:title', content: meta.title },
                    { property: 'og:description', content: meta.description },
                    { property: 'og:image', content: meta.image },

                    { property: 'twitter:url', content: window.location.origin },
                    { property: 'twitter:type', content: meta.type },
                    { property: 'twitter:title', content: meta.title },
                    { property: 'twitter:description', content: meta.description },
                    { property: 'twitter:image', content: meta.image },
                ]
            }
        },
        methods: {
            onSubmit: function () {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        return;
                    }

                    // Prevent multiple clicks.
                    if (this.sending)
                        return;

                    this.sending = true;

                    let url = this.appConfig.$api_url + "/contact/contest/";

                    let msg = {
                        email: this.email,
                        first_name: this.firstname,
                        last_name: this.lastname
                    };

                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(msg)
                    };

                    fetch(url, requestOptions)
                        .then(() => {
                            this.showSuccess = true;
                            this.email = "";
                            this.firstname = "";
                            this.lastname = "";
                            this.sending = false;
                            this.$nextTick(() => {
                                this.$refs.form.reset();
                            });
                        });
                });
            }
        }
    };
</script>
<style>
    .summitview-landing-page h1.summit {
        font-size: 60px;
        font-weight: bold;
    }

    .summitview-landing-page .menu {
        display: none;
    }

    .summitview-landing-page h1.summit .orange {
        color: #e67737;
    }

    @media (max-width: 1440px) {
        .summitview-landing-page .hero .content h1.summit {
            font-size: 50px;
        }

        .summitview-landing-page .hero .content p {
            font-size: 1em;
        }
    }

    @media (max-width: 700px) {
        .summitview-landing-page .event-registration .details {
            width: 100%;
            text-align: justify;
        }

        .summitview-landing-page .hero .content h1.summit {
            font-size: 40px;
        }

        .summitview-landing-page .hero .content {
            margin-top: 0;
            height: auto;
        }

            .summitview-landing-page .hero .content .text {
                background: none;
            }
    }
</style>