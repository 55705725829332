<template>
    <div class="event-registration">
        <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="desc" v-html="description"></div>
                <div v-if="canRegister">
                    <div class="field">
                        <ValidationProvider name="First name" rules="required" v-slot="{ errors }">
                            <label for="firstname">
                                First name <span v-if="errors[0]" class="err"> ({{ errors[0] }})</span>
                            </label>
                            <kInput id="firstname" v-model="firstname" type="text" placeholder="" />
                        </ValidationProvider>
                    </div>
                    <div class="field">
                        <ValidationProvider name="First name" rules="required" v-slot="{ errors }">
                            <label for="lastname">
                                Last name <span v-if="errors[0]" class="err"> ({{ errors[0] }})</span>
                            </label>
                            <kInput id="lastname" v-model="lastname" type="text" placeholder="" />
                        </ValidationProvider>
                    </div>
                    <div class="field">
                        <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                            <label for="email">
                                Email <span v-if="errors[0]" class="err"> ({{ errors[0] }})</span>
                            </label>
                            <kInput id="email" v-model="email" type="email" placeholder="you@domain.com" />

                        </ValidationProvider>
                    </div>
                    <div class="field">
                        <ValidationProvider name="Postal Code" rules="required|postal_code" v-slot="{ errors }">
                            <label for="postal_code">
                                Postal Code <span v-if="errors[0]" class="err"> ({{ errors[0] }})</span>
                            </label>
                            <kInput id="postal_code" v-model="postal_code" type="text" placeholder="A0A 0A0" />

                        </ValidationProvider>
                    </div>
                    <div class="buttons">
                        <gButton id="submit" :disabled='sending||invalid'>Register</gButton>
                    </div>

                    <Transition>
                        <div v-show="showSuccess" class="success">Thank you for registering, watch your email for more information.  We look forward to seeing you at the event!</div>
                    </Transition>
                </div>
                <div v-if="!canRegister">
                    <div class="success">Thank you for your interest.  Registration is now closed.  Stay tuned for more events in the future.</div>
                </div>

            </form>
        </ValidationObserver>

        <iframe :src="mapLink" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</template>
<script>
    import AppButton from '../AppButton.vue';
    import { Input } from '@progress/kendo-vue-inputs';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import { extend } from 'vee-validate';

    extend('postal_code', {
        validate: value => {
            var regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            return regex.test(value);
        },
        message: "Postal Code must in the format A0A 0A0"
    });

    let lists = {
        workshop_june: {
            id: "c0a6fd3f-1f57-4162-93d7-f78b314128a0",
            address: "1894 Ambrosi Road, Kelowna",
            mapLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1687.9328454772256!2d-119.45065997671838!3d49.87933829970212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537d8cb72119381f%3A0xaf3257f65f9792d6!2s1894%20Ambrosi%20Rd%2C%20Kelowna%2C%20BC%20V1Y%204R9!5e1!3m2!1sen!2sca!4v1682640186119!5m2!1sen!2sca",
            registrationEnd: new Date(2025, 5, 9, 12),
            description: `
                <div class='content'>
                    <div class="gift">
                        <div class="star"></div>
                        <div class="text">Complimentary Gift!</div>
                    </div>
                    <h1>Attention registered health care aides & students!</h1>
                    <div class="details">
                        <p>Don't miss our <b>FREE</b> upcoming workshop! </p>
                        <p>Hear from insightful speakers, connect with industry peers and discover the innovative solutions Gravitii has to offer you.<br /><br />Topics include Selfcare for HCAs, How to Build your Own Home Care Business and learn about Goals of Care from Dr. Kevin Wade, Palliative Care Physician and Gravitii's CMO.</p>
                        <div class='when-where orange'>
                            <div><b>Saturday June 10th, 2023</b></div>
                            <div>1:30 PM - 5:00 PM</div>
                            <div>1894 Ambrosi Road, Kelowna</div>
                        </div>
                    </div>
                </div>`
        }
    };

    let showSuccess = false;
    let invalid = false;
    let sending = false;
    let firstname = "";
    let lastname = "";
    let email = "";
    let postal_code = "";

    export default {
        components: {
            gButton: AppButton,
            ValidationObserver,
            ValidationProvider,
            kInput: Input
        },
        props: {
            list: String,
        },
        data: function () {
            return {
                lists,
                showSuccess,
                invalid,
                sending,
                firstname,
                lastname,
                email,
                postal_code,
            };
        },
        computed: {
            selectedList: function () {
                return this.lists[this.list] || { id: "", name: "", title: "", description: "" };
            },
            description: function () {
                return this.selectedList.description;
            },
            mapLink: function () {
                return this.selectedList.mapLink;
            },
            canRegister: function () {
                var regEnds = this.selectedList.registrationEnd;
                var now = new Date();
                return (now.getTime() < regEnds.getTime());
            }
        },
        methods: {
            onSubmit: function () {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        return;
                    }

                    // Prevent multiple clicks.
                    if (this.sending)
                        return;

                    this.sending = true;

                    let url = this.appConfig.$api_url + "/contact/list/" + this.selectedList.id;

                    let msg = {
                        first_name: this.firstname,
                        last_name: this.lastname,
                        email: this.email,
                        postal_code: this.postal_code
                    };

                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(msg)
                    };

                    fetch(url, requestOptions)
                        .then(() => {
                            this.showSuccess = true;
                            this.firstname = "";
                            this.lastname = "";
                            this.email = "";
                            this.postal_code = "";
                            this.message = "";
                            this.sending = false;
                            this.$nextTick(() => {
                                this.$refs.form.reset();
                            });
                        });
                });
            }
        }
    };
</script>
<style>
    .event-registration {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin: 2em;
        width: 1200px;
        max-width: 98%;
    }

        .event-registration form {
            flex: 0 0 100%;
            width: 100%;
        }

        .event-registration .success {
            background-color: #dcfce7;
            padding: 1em;
            border-radius: 6px;
            margin-top: 0.7em;
        }

        .event-registration iframe {
            margin-top: 1em;
        }

        .event-registration .desc {
            width: 100%;
            flex: 0 0 100%;
        }

        .event-registration .details {
            width: 75%;
            margin: 0 auto;
        }

        .event-registration .content {
            box-sizing: border-box;
            width: 100%;
            background-color: #fff;
            background: linear-gradient(219.87deg, rgba(251, 244, 234, 0.1) 0%, rgba(251, 240, 236, 1) 100%);
            text-align: center;
            padding: 1.5em;
            margin-bottom: 1em;
        }

        .event-registration .when-where {
            margin-top: 1em;
        }

        .event-registration .gift {
            width: 120px;
            height: 120px;
            margin-top: -60px;
            margin-left: -40px;
            position: absolute;
            opacity: 0.6;
        }

            .event-registration .gift .text {
                width: 100%;
                height: 100%;
                font-size: 0.9em;
                position: absolute;
                z-index: 4;
                color: #fff;
                text-align: center;
                align-items: center;
                align-content: center;
                display: flex;
                transform: rotate(-30deg);
            }

        .event-registration .star {
            z-index: 1;
            height: 100%;
            width: 100%;
            background: #601b85;
            position: absolute;
            text-align: left;
            padding: 0;
            margin: 0;
        }

            .event-registration .star::before {
                z-index: 2;
                height: 100%;
                width: 100%;
                background: #601b85;
                content: "";
                position: absolute;
                /* Rotate */
                -moz-transform: rotate(30deg);
                -webkit-transform: rotate(30deg);
                -ms-transform: rotate(30deg);
                -o-transform: rotate(30deg);
                transform: rotate(30deg);
            }

            .event-registration .star::after {
                z-index: 3;
                height: 100%;
                width: 100%;
                background: #601b85;
                content: "";
                position: absolute;
                /* Rotate */
                -moz-transform: rotate(-30deg);
                -webkit-transform: rotate(-30deg);
                -ms-transform: rotate(-30deg);
                -o-transform: rotate(-30deg);
                transform: rotate(-30deg);
            }


        .event-registration .orange div {
            color: #ED6A4A;
        }

        .event-registration .buttons {
            box-sizing: border-box;
            bottom: 25px;
            right: 25px;
            text-align: right;
        }

        .event-registration .field {
            margin-bottom: 1em;
        }

            .event-registration .field .err {
                display: inline;
                color: #f00;
                font-size: 0.8em;
            }

            .event-registration .field .k-input {
                height: 3em;
                line-height: 1.9em;
            }

        .event-registration .buttons button {
            width: 100px;
            height: 1em;
            font-size: 0.8em;
            line-height: 1em;
            border-radius: 20px;
            margin-right: 0.6em;
        }


    @media (max-width: 1025px) {
        .event-registration .gift {
            margin-top: -115px;
            margin-left: -10px;
        }

            .event-registration .gift .text {
            }
    }

    @media (max-width: 769px) {
        .event-registration .gift {
            width: 90px;
            height: 90px;
            margin-top: -100px;
            margin-left: -10px;
        }

            .event-registration .gift .text {
                font-size: 0.7em;
            }
    }
</style>